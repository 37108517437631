.wrapper {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.title {
    margin: 0px 20px 0px 10px;
}

