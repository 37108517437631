.wrapper {
  text-align: center;
}

.quotesWrapper {
  display: flex;
  justify-content: center;
}

.quoteBlock {
  border: 1px solid black;
  max-width: 500px;
  width: 100%;
  padding: 4px;
}

.quoteImage {
  width: 470px;
  height: 450px;
}

.header {
  color: white;
  background: black;
  padding: 10px;
 
}

.footer {
  color: white;
  background: black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-style: italic;
}