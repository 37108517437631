.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  background: transparent;
  box-shadow: 1px 1px 1px 1px inset white;
}

.link {
  color: black;
  padding: 10px;
  margin: 20px;
  font-size: 20px;
}